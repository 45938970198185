import React, { useEffect, useState } from "react";
import {Container, Row, Col} from 'react-bootstrap';
import GoogleReview from "../../../images/google-reviews-color.png"

// Style
import  './CustomerReviews.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from "react-slick";
import PlayVideo from "../../Play/PlayVideo";
import { useStaticQuery, graphql } from "gatsby";
import axios from "axios"
import wordsToNumbers from 'words-to-numbers';
const CustomerReviews = ( props ) => {
  const [testimonials, setTestimonials] = useState([])
  const [isClient, setClient] = useState(false);

  const strapiconfig = {
    headers: {
        Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
}
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig)// could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
  useEffect(() => {

    let url = `${process.env.GATSBY_STRAPI_SRC}/google-reviews/google-review-reviews`
    getitems(url);
    setClient(true);
  }, [])
  var testimonial_count = testimonials ? testimonials.length : 0;
  var rating_count = 0;
  testimonials && testimonials.length > 0 && testimonials.map((item, ind) => {
    rating_count += parseInt(wordsToNumbers(item.starRating));
  })
  var rating_avg = rating_count / testimonial_count;
  const latestReviewsScore = useStaticQuery(graphql`
        query {
            allStrapiGoogleReviewReviews {
                edges {
                    node {
                        id
                        starRating
                    }
                },
                pageInfo {
                    totalCount
                }
            }
        }
    `)
    const reviews = latestReviewsScore.allStrapiGoogleReviewReviews.edges;
    const reviewsCount = latestReviewsScore.allStrapiGoogleReviewReviews.pageInfo.totalCount;
    const wordToNumber = {'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5};
    let sumOfScores = 0;

    reviews.map((review) => {
        const reviewStringValue = review.node.starRating;
        sumOfScores += wordToNumber[reviewStringValue];
        return(sumOfScores)
    })

    const averageRating = Math.round(sumOfScores / reviewsCount * 10) / 10;

  const [isPlaying,setPlaying] = useState(false);

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst:true,
    responsive: [
      {
          breakpoint: 9999,
          settings: "unslick"
      },
      {
        breakpoint:767,
         settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
               
            }
    }
    ]
  }

  return(
    <div className="customer-reviews-bg">
          <div className="customer-reviews-block">
      <Container>
        <Row>
        <Col lg="6" className="position-static">
          <div className="customer-reviews-content">
        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
            <span className="reviews-text">Customer Reviews</span>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
           <h1>{props.Title}</h1>
           </ScrollAnimation>
            {props.Review ?            
           <div className="google-review-block">
           <ScrollAnimation animateIn='fadeIn' animateOnce={true} delay={300} className="google-review-block">
             <div className="">
               <img className="disable-max-width" src={GoogleReview} alt="Google Reviews Bentley Hurst"/>
             </div>
             <p>Rated <strong>{rating_avg ? parseFloat(rating_avg.toFixed(1)) : ''}/5</strong> from {testimonial_count ? testimonial_count : ''} Customer Reviews.</p>
             </ScrollAnimation>
           </div>
           : null }
           
      
          </div>
          {/* </ScrollAnimation> */}
          </Col>
          <Col lg="6" className="slick-pagination img-zoom">
          <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
          <Slider {...settings} >
          <div className="customer-reviews-img">
          <picture>
          <source media="(min-width:992px)" srcset={props.Image?.url} />
          <source media="(min-width:768px)" srcset={props.Image?.url} />
          <img className="" src={props.Image?.url} alt={props.Image?.alternativeText} />
         </picture>
         {props.Video_Url ?
         <strong className="play-btn" onClick = { e => {setPlaying(true)}} ><i className="icon-play"></i></strong>
         : null }
          </div>

        
          </Slider>
          </ScrollAnimation>
          </Col>
        </Row>
        <PlayVideo
              isOpen = { isPlaying } 
              isCloseFunction = { setPlaying }
              videoId={props.Video_Url}
              isAutoPlay={1}
            />
      </Container>
    </div>

    </div>
)
  }

export default CustomerReviews