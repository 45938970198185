import { Link } from "@StarberryUtils"
import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import PlayVideo from "../../Play/PlayVideo";
import ReactMarkdown from "react-markdown"
import { useStaticQuery, graphql } from "gatsby";
import SubscribeBlock from '@Components/SearchResult/SubscribeBlock/SubscribeBlock'
import StaticContactBlock from '@Components/Static/StaticContactBlock/StaticContactBlock'
import { CAREERS_PAGE_URL, FORSALE_Manchester_PAGE_URL, VALUATION_PAGE_UEL } from "@Components/common/site/constants";
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
import YouTube from '@u-wave/react-youtube';
// Images
import './ExploreManchester.scss';
import getVideoId from 'get-video-id';
const ExploreManchester = (props) => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        contactBlock(publicationState: LIVE) {
          Content
          Title
        }
        areaGuides(publicationState: LIVE) {
          id
            imagetransforms
          Title
          Banner_Title
          URL
          Embed_Video_URL
          Tile_Image {
            alternativeText
            url
          }
        }
      }
        
        glstrapi {
          teams(publicationState: LIVE) {
            id
            imagetransforms
            Name
            URL
            Embed_Video_URL
            Image {
              alternativeText
              url
            }
          }
        }



    }
`);
  const [isPlaying, setPlaying] = useState(false);
  const [videoId, setVideoId] = useState('');
  const [showVideo, setShowVideo] = useState(false);
  const playVideo = (video_id) => {
    // setVideoindex(video_index)   
    setShowVideo(true)
    setVideoindex(video_id)
  }
  const trackerVideo = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }
  const [videoindex, setVideoindex] = useState(false);
  const closePlay = (id) => {

    setPlaying(false)
    setVideoId('')
  }
  const classification = [
    {
      class: "space-top"
    },
    {
      class: ""
    },
    {
      class: "space-bottom"
    },
    {
      class: ""
    },
    {
      class: ""
    },
    {
      class: "space-bottom"
    },
    {
      class: ""
    },
    {
      class: "space-top"
    },
    {
      class: ""
    },
    {
      class: "space-bottom"
    },
    {
      class: ""
    },
    {
      class: "space-top"
    },
    {
      class: ""
    },
    {
      class: "space-bottom"
    },
    {
      class: ""
    },
    {
      class: "space-top"
    },
    {
      class: ""
    },
    {
      class: "space-bottom"
    },
    {
      class: ""
    },
    {
      class: "space-top"
    },
    {
      class: ""
    },
    {
      class: "space-bottom"
    },
    {
      class: ""
    },
    {
      class: "space-top"
    },
    {
      class: ""
    },
    {
      class: "space-bottom"
    },
    {
      class: ""
    },
  ];

  return (
    <>
      <div className="manchester-section">
        <Container>
          <Row>
            <Col xl="12">
              <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                <h1>{props.Title}</h1>
                <div className="manchester-content rich-text-editer-block">
                  <ReactMarkdown source={props.Content} allowDangerousHtml />
                </div>
              </ScrollAnimation>
            </Col>
            <Col xl="12">
              <div className="manchester-group d-md-flex">
                {props.collection === "Area_Guides" &&
                  data.glstrapi.areaGuides.map((node, index) => {
                    if (node.Embed_Video_URL) {
                      var videoid = getVideoId(node.Embed_Video_URL);
                    }
                    let processedImages = JSON.stringify({});
                    if (node?.imagetransforms?.Image_Transforms) {
                      processedImages = node.imagetransforms.Image_Transforms;
                    }
                    return (<>
                      {index === 4 ?
                        <div className="manchester-block space-top f-d-none">
                          <ScrollAnimation animateIn='fadeIn' animateOnce={true} delay={1000}>
                            <div className="manchester-block-img d-none d-lg-block">
                              <div className="manchester-block-bg">
                                <h2>Are you searching for a property in one of these areas?</h2>
                                <p>View our wide range of properties</p>
                                <Link to={FORSALE_Manchester_PAGE_URL.alias} className="btn btn-primary dark-bg">Property Search</Link>
                              </div>
                            </div>
                          </ScrollAnimation>
                        </div> : ''}
                      <div className={'manchester-block  ' + classification[index].class}>
                        <ScrollAnimation className="relative" animateIn='fadeIn' animateOnce={true} delay={200}>
                          <div className="hover-img" style={{ overflow: "hidden" }}>
                            {showVideo && videoindex === index ? null : node.Embed_Video_URL ? <strong href="" className={"play-btn "} onClick={(e) => { playVideo(index) }}><i className="icon-play"></i></strong> : ''}
                            {showVideo && videoindex === index &&
                              <YouTube
                                video={videoid.id}
                                autoplay
                                showRelatedVideos={false}
                                showInfo={false}
                                annotations={false}
                                onEnd={e => { setShowVideo(false) }}
                                modestBranding={1}
                                onPlaying={trackerVideo(props.data?.Name)}

                              />
                            }
                            {showVideo && videoindex === index ? null : <Link to={node.URL}><span className="img-tag">{node.Title}</span></Link>}
                            {showVideo && videoindex === index && <div onClick={(e) => { setShowVideo(false) }} className="close-iframe-btn"></div>}
                            <ImageTransform imagesources={node?.Tile_Image?.url} renderer="bgImg" imagename='area-guide.Tile_Image.smalltile'
                              attr={{ alt: '', className: 'manchester-block-img img-zoom' }}
                              imagetransformresult={processedImages} id={node.id} >
                              <Link to={node.URL} className="hidden-bk-link"></Link>

                            </ImageTransform>
                          </div>
                        </ScrollAnimation>
                      </div>
                      {index === 13 ? <div className="manchester-block f-d-none">
                        <ScrollAnimation animateIn='fadeIn' animateOnce={true} delay={1000}>
                          <div className="manchester-block-img d-none d-lg-block">
                            <div className="manchester-block-bg">
                              <h2>Looking to Sell or Let your property?</h2>
                              <p>Get in touch with our property experts to get an accurate valuation for your property</p>
                              <Link to={VALUATION_PAGE_UEL.alias} className="btn btn-primary dark-bg">Property Valuation</Link>
                            </div>
                          </div>
                        </ScrollAnimation>
                      </div> : ''}
                    </>);


                  })}
                {props.collection === "Our_Teams" && <>

                  {data.glstrapi.teams.map((node, index) => {
                    if (node.Embed_Video_URL) {
                      var videoid = getVideoId(node.Embed_Video_URL);
                    }
                    let processedImages = JSON.stringify({});
                    if (node?.imagetransforms?.Image_Transforms) {
                      processedImages = node.imagetransforms.Image_Transforms;
                    }
                    return (<>
                      {index === 4 ?
                        <div className="manchester-block space-top f-d-none">
                          <ScrollAnimation animateIn='fadeIn' animateOnce={true} delay={1000}>
                            <div className="manchester-block-img d-none d-lg-block">
                              <div className="manchester-block-bg">
                                <h3>{data.glstrapi?.contactBlock?.Title}</h3>
                                <ReactMarkdown source={data.glstrapi?.contactBlock?.Content} allowDangerousHtml />
                                <Link to={CAREERS_PAGE_URL.alias} className="btn btn-primary dark-bg">We’re hiring!</Link>
                              </div>
                            </div>
                          </ScrollAnimation>
                        </div> : ''}

                      <div className={'manchester-block ' + classification[index].class}>
                        <ScrollAnimation className="relative" animateIn='fadeIn' animateOnce={true} delay={200}>
                          <div className="hover-img" style={{ overflow: "hidden" }}>
                            {showVideo && videoindex === index ? null
                              : node.Embed_Video_URL ? <strong href="" className={"play-btn "} onClick={(e) => { playVideo(index) }}><i className="icon-play"></i></strong> : ''}
                            {showVideo && videoindex === index ? null : <Link to={node.URL}><span className="img-tag">{node.Name}</span></Link>}
                            {showVideo && videoindex === index && <div onClick={(e) => { setShowVideo(false) }} className="close-iframe-btn"></div>}

                            {showVideo && videoindex === index &&
                              <YouTube
                                video={videoid.id}
                                autoplay
                                showRelatedVideos={false}
                                showInfo={false}
                                annotations={false}
                                onEnd={e => { setShowVideo(false) }}
                                modestBranding={1}
                                onPlaying={trackerVideo(props.data?.Name)}

                              />
                            }
                            {/* <ImageTransform imagesources={node?.Image?.url} renderer="bgImg" imagename='team.Image.tail'
                              attr={{ alt: '', className: 'manchester-block-img img-zoom one' }}
                              imagetransformresult={processedImages} id={node.id} >
                              <Link to={node.URL} className="hidden-bk-link"></Link>
                            </ImageTransform> */}

                            <Link to={node.URL}>
                              <img src={node?.Image?.url}
                              alt={node?.Image?.alternativeText}
                              className="manchester-block-img img-zoom one" />
                            </Link>
                          </div>
                        </ScrollAnimation>
                      </div>   </>);
                  })}

                </>
                }


              </div>
            </Col>
          </Row>

          <PlayVideo
            isOpen={isPlaying}
            isCloseFunction={closePlay}
            videoId={videoId}
            isAutoPlay={1}
          />
        </Container>
      </div>
      {props.collection === "Area_Guides" &&
        <SubscribeBlock />
      }
      {props.collection === "Our_Teams" &&
        <StaticContactBlock teamvideoaskurl={"https://www.videoask.com/fmxlzpuaf"} />
      }

    </>
  )
}



export default ExploreManchester
