import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Modal from "react-bootstrap/Modal"
import './ImagesBlock.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import GetURL from '@Components/common/site/get-url';
import DubaiForm from "@Components/forms/dubai-properties"
import ReactMarkdown from "react-markdown"
const ImagesBlock = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [proType, setproType] = useState('') 
  const [content, setcontent] = useState('') 
  const [popimg, setpopimg] = useState('') 
  const handleShow = (event, cont, img) => {   
    setShow(true);
    setproType(event)
    setcontent(cont)
    setpopimg(img)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Dubai Properties',
      'formType': event,
      'formId': 'Dubai Properties',
      'formName': 'Dubai Properties',
      'formLabel': 'Dubai Properties'
    });   
}
  return (
    <div className="images-section">
      <Container>
        <Row>
          {props.Tiles.map((item, index)=>{
            return (
              <Col lg="3" md="6" className={item.Tile_URL?.Alias ? "img-zoom" : "img-zoom tail-fix" }>
              <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
              <div className="image-tiles">
              {item.Tile_URL?.Alias ?
              <GetURL class="tail-link" label={item.Tile_Label} alias={item.Tile_URL?.Alias}  /> : 
              <a className="tail-link" onClick={()=>handleShow(item.Title, item.Content, item.Image)}></a>
              }
              <picture>
              <source media="(min-width:992px)" srcset={item.Image?.url} />
              <source media="(min-width:768px)" srcset={item.Image?.url} />
              <img className="" src={item.Image?.url} alt={item.Image?.alternativeText} />
               </picture>
               <span className="img-tag">{item.Tile_URL?.Alias ? <GetURL label={item.Tile_Label} alias={item.Tile_URL?.Alias}  /> : item.Tile_Label}</span>
             </div>
             </ScrollAnimation>
              </Col>
            )
          })}
        </Row>
      </Container>
      <Modal
              id="share_modal_mobile"
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              size="xl"
       		 dialogClassName="modal-90w modal-form dubai-page-popup"
            >
              <Modal.Header closeButton className="contact-close-btn memberPopup">
                <Modal.Title className="w-100"><h6 className="content-primary-color mb-0">{proType}</h6></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="d-flex"> 
                  <Col md={8}>
              <ReactMarkdown source={content} allowDangerousHtml />
              </Col>
              <Col md={4}>
              <picture>
              <source media="(min-width:992px)" srcset={popimg?.url} />
              <source media="(min-width:768px)" srcset={popimg?.url} />
              <img className="img-flex" src={popimg?.url} alt={popimg?.alternativeText} />
               </picture>
              </Col>
              </Row>
              <div className="dubaiform-top">
			  <DubaiForm  propname={proType} />
        </div>

              </Modal.Body>
            </Modal> 
    </div>


  )
}



export default ImagesBlock