import { Link } from "@StarberryUtils"
import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import ScrollAnimation from 'react-animate-on-scroll';
// Style
import './CaseStudies.scss';
import ReactMarkdown from "react-markdown"
import { useStaticQuery, graphql } from "gatsby";
import moment from "moment"
import { BLOGS_PAGE_URL } from "@Components/common/site/constants";
import GetURL from '@Components/common/site/get-url';
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
const CaseStudies = (props) => {
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      caseStudiesAndInsights(publicationState: LIVE, limit: 3, sort: "Date:desc") {
        id
                imagetransforms
            Date
            Title
            URL
            Tile_Image {
                url
                alternativeText
              }
          }
        }
      }
    `);


  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    mobileFirst: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick"
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      }
    ]
  }
  return (
    <div className="case-studies">
      <Container>
        <Row>
          <Col xs>
            <div className="case-studies-block">
              <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                <div className="case-studies-content">
                  <h2 className="d-none d-lg-block">{props.Title}</h2>
                  <div className="rich-text-editer-block">
                    <ReactMarkdown source={props.Content} allowDangerousHtml />
                  </div>
                </div>
              </ScrollAnimation>
              <div className="case-studies-list slick-pagination">
                {props.View_More != null &&
                  <div className="view-more-wrap">
                    <GetURL isLinkArrow="true" label={props.View_More.CTA_1_Label} alias={props.View_More.CTA_1_URL.Alias} class={'link-text'} />
                  </div>
                }
                <Slider {...settings} >
                  {data.glstrapi.caseStudiesAndInsights.map((node, index) => {
                    let processedImages = JSON.stringify({});
                    if (node?.imagetransforms?.Image_Transforms) {
                      processedImages = node.imagetransforms.Image_Transforms;
                    }
                    return (

                      <div className="case-studies-news img-zoom">
                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                          <div className={index === 1 ? "case-studies-img middle" : "case-studies-img"}>
                            <Link to={BLOGS_PAGE_URL.alias + '/' + node.URL}>
                              <ImageTransform imagesources={node?.Tile_Image?.url} renderer="srcSet" imagename='case-studies-and-insights.Image.tail'
                                attr={{ alt: node?.Tile_Image?.alternativeText, className: '' }}
                                imagetransformresult={processedImages} id={node?.id} />
                            </Link>
                          </div>
                          <div className="case-studies-title">
                            <span>{moment(node.Date).format('DD MMMM YYYY')}</span>
                            <h3><Link to={BLOGS_PAGE_URL.alias + '/' + node.URL}>{node.Title}</Link></h3>
                          </div>
                        </ScrollAnimation>
                      </div>
                    )
                  })
                  }


                </Slider>

              </div>


            </div>

          </Col>
        </Row>
      </Container>
    </div>
  )
}



export default CaseStudies