import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import  './StaticPropertyMap.scss';
import LocRatingMap from '@Components/map/loc-rating-map';
import { MapMarkerInfoBox } from '@Components/map/map-marker';
const StaticPropertyMap = ( props ) => {
    let mapItems = [];
    let mapItem = {};
    mapItem['lat']  = props.mapData?.Latitude      
    mapItem['lng']  = props.mapData?.Longitude
    const mapMarkerImageShowFlag = 0;
    let mapItemHtml = MapMarkerInfoBox("test", mapMarkerImageShowFlag);
    mapItem['html'] = mapItemHtml;
    mapItems.push( mapItem );
  return(
<div className="staticproperty-map d-xl-flex">
    <ScrollAnimation animateIn='slideIn' className="map">
        <LocRatingMap data={mapItems} />
        </ScrollAnimation>
  
    
</div>
)
  }



export default StaticPropertyMap