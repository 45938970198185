import React,{useState} from "react"
import {Container, Row, Col} from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
// Style
import  './BenefitSection.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import PlayVideo from "../../Play/PlayVideo";

const BenefitSection = ( props ) => {

  const [isPlay,setPlay] = useState(false);
  const [isPlaying,setPlaying] = useState(false);

  return(
    <div className="benefit-block">
      <Container>

        {props.Blocks.map((item, index) => {
          return(
            <Row className="align-items-lg-center justify-content-lg-between  top-lg-space">
            <Col lg="5" className={index % 2 == 0 ? "img-zoom  z-ind" : "order-lg-2 img-zoom z-ind"}>
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
            <div className="benefit-block-img">
            <picture>
            <source media="(min-width:992px)" srcset={item?.Image?.url} />
            <source media="(min-width:768px)" srcset={item?.Image?.url} />
            <img className="" src={item?.Image?.url} alt={item?.Image?.alternativeText} />
           </picture>
           {item.Embed_Video_URL ?
           <strong href="" className="play-btn" onClick = { e => {setPlaying(true)}}><i className="icon-play"></i></strong>
           : ''}
            </div>
            </ScrollAnimation>
            </Col>
            <Col lg="6" className={index % 2 == 0 ? '': 'order-lg-1'}>
            <ScrollAnimation animateIn='fadeIn' animateOnce={true} delay={500}>
            <div className="benefit-block-content ">
             <h2>{item.Title}</h2>
             <div className="rich-text-editer-block">
             <ReactMarkdown  source={item.Content} allowDangerousHtml />
             </div>
            </div>
            </ScrollAnimation>
            </Col>
          </Row>
          )
        })}


       
        <PlayVideo
              isOpen = { isPlay } 
              isCloseFunction = { setPlay }
              videoId="luZRKtAUb2A"
              isAutoPlay={1}
            />
        <PlayVideo
              isOpen = { isPlaying } 
              isCloseFunction = { setPlaying }
              videoId="cj902sRs-dg"
              isAutoPlay={1}
            />
      </Container>
    </div>  
)
  }

export default BenefitSection