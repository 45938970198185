import { Link } from "@StarberryUtils"
import * as React from "react"
import { Container, Row, Col } from 'react-bootstrap';
// Images

import './FormBlock.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactMarkdown from "react-markdown"
import CareersForm from "@Components/forms/career";
import { uknumberformatFront } from "@Components/common/common-functions"
const FormBlock = ( props ) => {

  return (
    <div className="form-section">
      <Container>
        <Row>
          <Col lg="7">
            <div className="form-content">
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
              <h2>{props.Title}</h2>
              <div className="rich-text-editer-block">
              <ReactMarkdown source={props.Content} allowDangerousHtml />
              </div>
              </ScrollAnimation>
            </div>
            <div className="form-block-col">
              {/* <Form>
                <ScrollAnimation animateIn='fadeIn' animateOnce={true} delay={300}>
                  <Form.Group>
                    <Form.Control type="text" placeholder="Your Name" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control type="email" placeholder="Email Address" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control type="text" placeholder="Telephone Number" />
                  </Form.Group>
                  <Form.Group className="attach-file-wrap">
                    <Form.Control type="text" placeholder="Click to attach your CV" />
                    <input type="file" className="attach-file" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control type="text" placeholder="Any other info you’d like to share?" />
                  </Form.Group>
                  <Form.Group>
                    <Button variant="primary" type="submit">Submit</Button>
                  </Form.Group>
                </ScrollAnimation>
              </Form> */}
              <CareersForm />
            </div>
          </Col>
          {props.Speak_With &&
          <Col lg="5" className="d-lg-flex justify-content-lg-end align-items-lg-start">
          <ScrollAnimation animateIn='fadeIn' animateOnce={true} delay={200}>
            <div className="member-card">
              <div className="member-card-info">
                <h2>Speak with our team</h2>
                {/* <p>Lorem ipsum dolor sit amet consectetur adipiscing elit volat ipsum.</p> */}
                <Link href={'/about-us/our-team/'+props.Speak_With?.URL} className="btn btn-outline">Get in touch</Link>
              </div>
              <div className="negotiator d-flex align-items-center">
                        <figure><img src={props.Speak_With?.Image?.url} alt={props.Speak_With?.Image?.alternativeText} /></figure>
                        <div className="info">
                            <strong className="name">{props.Speak_With?.Name}</strong>
                            <p className="post">{props.Speak_With?.Designation}</p>
                            <a className="contact-no" href={'tel:'+uknumberformatFront(props.Speak_With?.Phone)}>{uknumberformatFront(props.Speak_With?.Phone)}</a>
                        </div>
                    </div>
            </div>
            </ScrollAnimation>
          </Col>
          }
        </Row>
      </Container>
    </div>


  )
}



export default FormBlock