import React, {useState} from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';
// import Img from "gatsby-image";
import SlickSlider from 'react-slick';
/**
 * Components
 */

import { Star } from '@Components/icon/icon';
import ReactReadMoreReadLess from "react-read-more-read-less"
/**
 * Assets
 */

import './styles/_index.scss';

const Reviews = (props) => {
    // const [active, setActive] = useState('+ Read More');
    const data = useStaticQuery(graphql`{
  allStrapiGoogleReviewReviews(
    limit: 1000
    sort: {fields: createTime, order: DESC}
    filter: {starRating: {in: ["FOUR", "FIVE"]}}
  ) {
    edges {
      node {
        comment
        createTime(fromNow: true)
        name
        reviewId
        reviewer {
          displayName
          profilePhotoUrl
        }
        starRating
        updateTime(fromNow: true)
      }
    }
    pageInfo {
      totalCount
    }
  }
  reviews: file(relativePath: {eq: "reviews-logo-color.png"}) {
    childImageSharp {
      gatsbyImageData(width: 130, height: 65, layout: CONSTRAINED)
    }
  }
  avatar1: file(relativePath: {eq: "avatar1.png"}) {
    childImageSharp {
      gatsbyImageData(width: 63, height: 63, layout: CONSTRAINED)
    }
  }
  avatar2: file(relativePath: {eq: "avatar2.png"}) {
    childImageSharp {
      gatsbyImageData(width: 63, height: 63, layout: CONSTRAINED)
    }
  }
  avatar3: file(relativePath: {eq: "avatar3.png"}) {
    childImageSharp {
      gatsbyImageData(width: 63, height: 63, layout: CONSTRAINED)
    }
  }
}
`);
    const reviews = data.allStrapiGoogleReviewReviews.edges;
    const reviewsCount = data.allStrapiGoogleReviewReviews.pageInfo.totalCount;
    const wordToNumber = {'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5};
    let sumOfScores = 0;

    reviews.map((review) => {
        const reviewStringValue = review.node.starRating;
        sumOfScores += wordToNumber[reviewStringValue];
        return(sumOfScores)
    })
    // const averageRating = Math.round(sumOfScores / reviewsCount * 10) / 10;


    return (
        <div className="static-reviews"  data-aos="fade-up">
            <Container>

                <h2>{props.Title}</h2> 
                {props.isPage ? <>
                    {data.allStrapiGoogleReviewReviews.edges.map((item) => (
                    <div className="review single">
                        <div className="review__inner">
                            <div className="stars">
                                {/* {item.node.starRating} */}
                                {item.node.starRating === 'FOUR' ?
                                <>
                                <Star />
                                <Star />
                                <Star />
                                <Star />
                                </>
                                :
                                <>
                                <Star />
                                <Star />
                                <Star />
                                <Star />
                                <Star />
                                </>}
                            </div>
                            <div className="google-content">
                            {item.node?.comment && (
                            <ReactReadMoreReadLess
                                charLimit={100}
                                readMoreText={"more"}
                                readLessText={"less"}
                                readMoreClassName="read-more-less--more"
                                readLessClassName="read-more-less--less"
                                >
                                {item.node?.comment}
                            </ReactReadMoreReadLess>
                            )}
                            </div>
                            <Row className="flex-nowrap google-top">
                                <Col xs="auto">
                                    <div className="icon">
                                        <img src={item.node.reviewer.profilePhotoUrl} />
                                    </div>
                                </Col>
                                <Col xs="auto">
                                    <h5>{item.node.reviewer.displayName}</h5>
                                    {/* <small>{item.node.createTime}</small> */}
                                </Col>
                            </Row>
                        </div>
                    </div>
                                        ))}
                                        </>
                :
                <SlickSlider
                    arrows={false}
                    dots={false}
                    infinite={false}
                    speed={500}
                    slidesToShow={3}
                    slidesToScroll={1}
                    responsive={[{
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            dots: true
                        }
                    }]}
                >
                                        {data.allStrapiGoogleReviewReviews.edges.slice(0, 8).map((item) => (
                    <div className="review">
                        <div className="review__inner">
                            <div className="stars">
                                {/* {item.node.starRating} */}
                                {item.node.starRating === 'FOUR' ?
                                <>
                                <Star />
                                <Star />
                                <Star />
                                <Star />
                                </>
                                :
                                <>
                                <Star />
                                <Star />
                                <Star />
                                <Star />
                                <Star />
                                </>}
                            </div>
                            <div className="google-content">
                            <ReactReadMoreReadLess
                                charLimit={100}
                                readMoreText={"more"}
                                readLessText={"less"}
                                readMoreClassName="read-more-less--more"
                                readLessClassName="read-more-less--less"
                                >
                                {item.node?.comment}
                            </ReactReadMoreReadLess>
                            </div>
                            <Row className="flex-nowrap google-top">
                                <Col xs="auto">
                                    <div className="icon">
                                        <img src={item.node.reviewer.profilePhotoUrl} />
                                    </div>
                                </Col>
                                <Col xs="auto">
                                    <h5>{item.node.reviewer.displayName}</h5>
                                    <small>{item.node.createTime}</small>
                                </Col>
                            </Row>
                        </div>
                    </div>
                                        ))}
                </SlickSlider>
                }
            </Container>
        </div>
    )
}

export default Reviews

