import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import ScrollAnimation from 'react-animate-on-scroll';
// Style
import './AboutSection.scss';
import GetURL from '@Components/common/site/get-url';
import { getSrc } from "gatsby-plugin-image"
const AboutSection = (props) => {
  return (
    <div className="about-section">
      <Container>
        <Row>
          <Col xs>
            <div className="grid">
              <ScrollAnimation animateIn='fadeIn' animateOnce={true} className="box-1 about-content">
                <h1>{props.Title}</h1>
                <div className="rich-text-editer-block">
                  <ReactMarkdown source={props.Content} allowDangerousHtml />
                </div>
                {props.CTA_1_Label != null && props.CTA_1_URL != null &&
                  <div className="btn-row">
                    <GetURL isLinkArrow="true" label={props.CTA_1_Label} alias={props.CTA_1_URL.Alias} class={'link-text'} />
                  </div>
                }
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeIn' animateOnce={true} className="box-2">
                <img className="bg-img" src={getSrc(props.Left_Image?.url_sharp)} alt={props.Left_Image?.alternativeText} />
                <div className="rich-text-editer-block">
                  <ReactMarkdown source={props.Left_Image_Caption} allowDangerousHtml />
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeIn' animateOnce={true} className="box-3">
                <img className="bg-img" src={getSrc(props.Right_Image?.url_sharp)} alt={props.Right_Image?.alternativeText} />
                <div className="rich-text-editer-block">
                  <ReactMarkdown source={props.Right_Image_Caption} allowDangerousHtml />
                </div>
              </ScrollAnimation>
            </div>
          </Col>
        </Row>
      </Container>

    </div>
  )
}



export default AboutSection