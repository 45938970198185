import { Link } from "@StarberryUtils"
import React, { useEffect, useState } from "react";
import GoogleReviews from "../../../images/google-reviews1.svg"
import ScrollAnimation from 'react-animate-on-scroll';
import ReactMarkdown from "react-markdown"
import { useStaticQuery, graphql } from "gatsby";
import './BlockGroup.scss';
import GetURL from '@Components/common/site/get-url';
import axios from "axios"
import wordsToNumbers from 'words-to-numbers';
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
const BlockGroup = (props) => {
  const [testimonials, setTestimonials] = useState([])
  const [isClient, setClient] = useState(false);


  const strapiconfig = {
    headers: {
        Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
}
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig)// could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
  useEffect(() => {

    let url = `${process.env.GATSBY_STRAPI_SRC}/google-reviews/google-review-reviews`
    getitems(url);
    setClient(true);
  }, [])
  var testimonial_count = testimonials ? testimonials.length : 0;
  var rating_count = 0;
  testimonials && testimonials.length > 0 && testimonials.map((item, ind) => {
    rating_count += parseInt(wordsToNumbers(item.starRating));
  })
  var rating_avg = rating_count / testimonial_count;
  const [hoverclass, setHoverclass] = useState(false)

  const getOnScroll = () => {
  }

  const data = useStaticQuery(graphql`
  query{
    glstrapi {
      dashboard {
        imagetransforms
        id
        Big_CTA {
          CTA_1_Label
          CTA_1_URL {
            Alias
          }
        }
        Big_Content
        Big_Image {
          url
        }
        Count
        Count_Text
        Days
        Days_Text
        Show_Review
        Google_Rate
        Total_Reviews
        Small_Content
        Small_Image {
          url
        }
        Small_CTA {
          CTA_1_Label
          CTA_1_URL {
            Alias
          }
        }
      }
    }
    allStrapiGoogleReviewReviews {
      edges {
          node {
              id
              starRating
          }
      },
      pageInfo {
          totalCount
      }
  }

}
  `);
  const reviews = data.allStrapiGoogleReviewReviews.edges;
  const reviewsCount = data.allStrapiGoogleReviewReviews.pageInfo.totalCount;
  const wordToNumber = {'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5};
  let sumOfScores = 0;

  reviews.map((review) => {
      const reviewStringValue = review.node.starRating;
      sumOfScores += wordToNumber[reviewStringValue];
      return(sumOfScores)
  })

  const averageRating = Math.round(sumOfScores / reviewsCount * 10) / 10;
  let processedImages = JSON.stringify({});
  if (data.glstrapi?.dashboard?.imagetransforms?.Image_Transforms) {
      processedImages = data.glstrapi?.dashboard?.imagetransforms.Image_Transforms;
  }
  return(
  <div className="block-row">
   <div className="d-lg-flex" onWheel={getOnScroll}>
      <ScrollAnimation animateIn='slideIn' className="block-row-left">
        <div className="google-rating-block">
              <div className="google-rating">
          <div>
            <img src={GoogleReviews} alt="Google Reviews Bentley Hurst"/>
          </div>
          <p>Rated <strong>{rating_avg ? parseFloat(rating_avg.toFixed(1)) : ''}/5</strong> from {testimonial_count ? testimonial_count : ''} Reviews</p>
         
        </div>
        <Link to="/about-us/reviews" className="link-text"> <span className={hoverclass ? "red btn-arrow" : "btn-arrow"} onMouseEnter={()=>setHoverclass(true)} onMouseLeave={()=>setHoverclass(false)}><i className="icon-arrow"></i></span>Customer Reviews</Link>
        </div>
        <div className="block-row-bottom">
        <strong className="day-text">{data.glstrapi?.dashboard?.Days}</strong>
        <div className="rich-text-editer-block">
          <ReactMarkdown source={data.glstrapi?.dashboard?.Days_Text} allowDangerousHtml />
          </div>
          </div>
        </ScrollAnimation>
       
        <ScrollAnimation animateIn='slideIn' className="block-row-right">

          <ImageTransform imagesources={data.glstrapi?.dashboard?.Big_Image?.url} renderer="bgImg" 
          imagename='dashboard.Big_Image.bigbg'
                                     attr={{ alt: '', className: 'big-image-bg'}}
                                     imagetransformresult={processedImages} id={data.glstrapi?.dashboard?.id} />

            <div className="block-row-content rich-text-editer-block"> 
            <ReactMarkdown source={data.glstrapi?.dashboard?.Big_Content} allowDangerousHtml />
            </div>
            {data.glstrapi?.dashboard?.Big_CTA?.CTA_1_Label ?
            <GetURL isLinkArrow="true" label={data.glstrapi?.dashboard?.Big_CTA?.CTA_1_Label} alias={data.glstrapi?.dashboard?.Big_CTA?.CTA_1_URL?.Alias} class={'link-text'} />
            : ''}

      
        </ScrollAnimation>

      </div>
      <div className="d-md-flex">

        
          <ScrollAnimation animateIn='slideIn' className="block-row-leftbox order-lg-2">
          <strong className="day-text">{data.glstrapi?.dashboard?.Count}</strong>

          <div className="rich-text-editer-block">
            <ReactMarkdown source={data.glstrapi?.dashboard?.Count_Text} allowDangerousHtml />

            </div>
            </ScrollAnimation>
         

        <ScrollAnimation animateIn='slideIn' className="block-row-rightbox order-lg-1">
          <ImageTransform imagesources={data.glstrapi?.dashboard?.Small_Image?.url} renderer="bgImg" 
          imagename='dashboard.Small_Image.smallbg'
                                     attr={{ alt: '', className: 'small-image-bg'}}
                                     imagetransformresult={processedImages} id={data.glstrapi?.dashboard?.id} />
          <div className="rich-text-editer-block">
          <ReactMarkdown source={data.glstrapi?.dashboard?.Small_Content} allowDangerousHtml />
          </div>
          {data.glstrapi?.dashboard?.Small_CTA?.CTA_1_Label ?
          <GetURL isLinkArrow="true" label={data.glstrapi?.dashboard?.Small_CTA?.CTA_1_Label} alias={data.glstrapi?.dashboard?.Small_CTA?.CTA_1_URL?.Alias} class={'link-text'} />
          : ''}
        </ScrollAnimation>
      </div>


    </div>
  )
}



export default BlockGroup