import React,{useState} from "react"
import {Container, Row,Col } from 'react-bootstrap';
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import ReactMarkdown from "react-markdown"
// Style
import  './CustomersAdvocates.scss';
import { useStaticQuery, graphql } from "gatsby";
import GetURL from '@Components/common/site/get-url';
import getVideoId from 'get-video-id';
import YouTube from '@u-wave/react-youtube';
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
const CustomersAdvocates = ( props ) => {
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
          videoTestimonials(publicationState: LIVE, limit: 10, where: {Category:"Clients"}) {
            id
            imagetransforms
            Name
            Destination
            Video_Url
            Category
            Photo {
              alternativeText
              url
            }
          }
        }
      }
    `);
  const [videoId, setVideoId] = useState('');
  const [showVideo, setShowVideo] = useState(false);
  const [videoindex, setVideoindex] = useState(null);
  const playVideo = (id, Embed_Video_URL) => {  
    var videoid = getVideoId(Embed_Video_URL);
    setShowVideo(true)
    setVideoindex(id)   
    setVideoId(videoid.id)
}
const closePlay = () => {
  setShowVideo(false)  
  setVideoId('')
  setVideoindex(null)   
}
const trackerVideo = (event) => {    
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'Video Play Btn',
    'formType': event,
    'formId': 'Video Play',
    'formName': 'Video',
    'formLabel': 'Video'
  });
}
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,

    responsive: [
        {
            breakpoint: 991,
            settings: {
                dots: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '0px',
            }
        },
        {
          breakpoint:767,
          settings: {
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: false,
              centerPadding: '0px',
          }
      }
    ]
};

  return(

<div className="customers-advocates">
 <Container>
   <Row>
     <Col xs>
       <div className="customers-list">
       <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
         <div className="customers-content">
         <h2>{props.Title}</h2>
         <div className="rich-text-editer-block">
        <ReactMarkdown source={props.Content} allowDangerousHtml />
        </div>
         </div>
         </ScrollAnimation>
         </div>

     </Col>
   </Row>
   <div className="customers-slider slick-pagination">
   {props.View_More != null &&
            <div className="view-more-wrap">
              <GetURL isLinkArrow="true" label={props.View_More.CTA_1_Label} alias={props.View_More.CTA_1_URL.Alias} class={'link-text'} />
              
            </div>
          }
             <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
             <div className="d-flex25 customers-slide-wrap">
           <Slider {...settings}>
           {data.glstrapi.videoTestimonials.map((node, index) => {
             let processedImages = JSON.stringify({});
             if (node?.imagetransforms?.Image_Transforms) {
                 processedImages = node.imagetransforms.Image_Transforms;
             }
                  return (

             <div className="customer-col img-zoom">
               {showVideo && videoindex === index && <div onClick={(e) => {closePlay(e)}} className="close-iframe-btn"></div> }
               <div className="customer-img">
               {showVideo && videoindex === index &&
                        <YouTube
                        video={videoId}
                        autoplay
                        showRelatedVideos={false}
                        showInfo={false}
                        annotations={false}
                        onEnd={e => {closePlay(e)}}
                        modestBranding={1}
                        onPlaying={trackerVideo(node?.Name)}

                        />
                        }
<ImageTransform imagesources={node?.Photo?.url} renderer="srcSet" imagename='video-testimonial.Image.tail'
                                     attr={{ alt: node?.Photo?.alternativeText, className: ''}}
                                     imagetransformresult={processedImages} id={node?.id} ></ImageTransform>
               {/* <img src={node.Photo?.url} alt={node.Photo?.alternativeText}/> */}
               {node.Video_Url !== "" ?
               <>
               <strong onClick={(e) => {
                                            playVideo(index, node.Video_Url);
                                          }} className="play-btn"><i className="icon-play"></i></strong>
              
                </>
                : null}
               </div>
               <div className="customers-info">
               <h3>{node.Name}</h3>
               <span>{node.Destination}</span>
               </div>
             </div>
             )
            })
            }
             {/* <div className="customer-col img-zoom">
               <div className="customer-img">
               <img src={CustomerImg2} alt=""/>
               <Link href="" className="play-btn"><i className="icon-play"></i></Link>
               </div>
               <div className="customers-info">
               <h4>Andrew Jenkinson</h4>
               <span>Buyer</span>
               </div>
             </div>

             <div className="customer-col img-zoom">
               <div className="customer-img">
               <img src={CustomerImg3} alt=""/>
               <Link href="" className="play-btn"><i className="icon-play"></i></Link>
               </div>
               <div className="customers-info">
               <h4>Emma Blair</h4>
               <span>Seller</span>
               </div>
             </div>

             <div className="customer-col img-zoom">
               <div className="customer-img">
               <img src={CustomerImg4} alt=""/>
               <Link href="" className="play-btn"><i className="icon-play"></i></Link>
               </div>
               <div className="customers-info">
               <h4>Elizabeth Jones</h4>
               <span>Tenant</span>
               </div>
             </div>

             <div className="customer-col img-zoom">
               <div className="customer-img">
               <img src={CustomerImg4} alt=""/>
               <Link href="" className="play-btn"><i className="icon-play"></i></Link>
               </div>
               <div className="customers-info">
               <h4>Chris Smith</h4>
               <span>Property Management</span>
               </div>
             </div>
             <div className="customer-col img-zoom">
               <div className="customer-img">
               <img src={CustomerImg4} alt=""/>
               <Link href="" className="play-btn"><i className="icon-play"></i></Link>
               </div>
               <div className="customers-info">
               <h4>Elizabeth Jones</h4>
               <span>Tenant</span>
               </div>
             </div> */}

            

             </Slider>

             </div>
             </ScrollAnimation>
             
           </div>
           {/* <PlayVideo
                          isOpen={isPlaying}
                          isCloseFunction={setPlaying}
                          videoId={videourl}
                          isAutoPlay={1}
                        /> */}
 </Container>


</div>
)
  }



export default CustomersAdvocates

