import React,{ useEffect, useState } from "react";
import {Button, Form, Container, Row, Col} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import  './CalculateBlock.scss';

import PropTypes from "prop-types"
import { filterNumber, numberFormat, pmt } from "./util"
import { defaultValues } from "./config"
import {
  calculate,
  countries,
  // propertyTypes,
  // buyerTypes,
} from "uk-ireland-stampduty-calculator"
const durationOptions = [10, 15, 20, 30, 35]
const propertyTypes = [
  {
    value: "residential",
    label: "Residential",
  },
  // {
  //   value: "commercial",
  //   label: "Commercial",
  // },
]

const buyerTypes = [
  {
    value: "first",
    label: "First Time Buyer",
  },
  {
    value: "home",
    label: "Home Mover (main residence and not a first time buyer)",
  },
  {
    value: "investor",
    label: "Second Homes / BTL",
  },
]
const CalculatorBlock  = ( props ) => {
//   const [property, setProperty] = useState('')
//   const [deposit, setDeposit] = useState('')
//   const [interest, setInterest] = useState('')
//   const [repayment, setRepayment] = useState('')
//   const [price, setPrice] = useState('')

//   const onChange = useCallback((e)=>{
//     let val = e.target.value
//     let re = /^[0-9\b]+$/;

//     if(re.test(val) || val===""){
//       let targetName = e.target.name;
//       switch(targetName) {
//         case "property" :
//           setProperty(val);
//           break;
//         case "deposit" :
//           setDeposit(val);
//           break;
//         case "interest" :
//           setInterest(val);
//           break;
//         case "repayment" :
//           setRepayment(val);
//           break;
//         case "price" :
//           setPrice(val);
//           break;
//         default :
//         return
//       }
//     }
//  },[]);
const {
  propertyPrice,
  depositAmount,
  loadDuration,
  interestRate,
  currency,
  defaultResult,
  pricePrefix,
  // instantResult,
  // durationSuffix,
  // yearSuffix,
  // yearSelect,
} = props

const prefix = pricePrefix ? currency : ""
const [validated, setValidated] = useState(false)
const [showerror, setShowerror] = useState(false)
const [purchasePrice, setPurchasePrice] = useState(
  prefix + numberFormat(propertyPrice)
)
const [deposit, setDeposit] = useState(prefix + numberFormat(depositAmount))
const [duration, setDuration] = useState(loadDuration)
const [interest, setInterest] = useState(interestRate)
const [loan, setLoan] = useState("")
const [result, setResult] = useState("")

const handlePrice = event => {
  let val = filterNumber(event.target.value)
  if(event.target.value !== "£" && event.target.value !== "£0" && event.target.value !== "0" && event.target.value !== ""){
  setPurchasePrice(prefix + numberFormat(val))
  if (parseInt(filterNumber(deposit)) && parseInt(val)) {
    let loan = parseInt(val) - parseInt(filterNumber(deposit))
    setLoan(prefix + numberFormat(loan))
  } else {
    setLoan(prefix + 0)
  }
  }
  else {
    setPurchasePrice('')
    setLoan()
  }
}

const handleDeposit = event => {
  let d = filterNumber(event.target.value)
  if(event.target.value !== '£' && event.target.value !== "£0" && event.target.value !== '0' && event.target.value !== ""){
  setDeposit(prefix + numberFormat(d))

  if (parseInt(filterNumber(purchasePrice)) && parseInt(d)) {
    let loan2 = parseInt(filterNumber(purchasePrice)) - parseInt(d)
    setLoan(prefix + numberFormat(loan2))
  } else {
    setLoan(prefix + 0)
  }
  }
  else {
    setLoan('')
    setDeposit('')
  }
}

const handleDuration = event => {
    setDuration(filterNumber(event.target.value))
}

const handleInterest = event => {
  setInterest(event.target.value.replace(/[^\d.]/g, ""))
}

// const handleLoan = event => {
//   setLoan(prefix + numberFormat(filterNumber(event.target.value)))
// }

const getResult = (interest, duration, loan) => {
  let result = -pmt(
    interest / 100 / 12,
    filterNumber(duration) * 12,
    filterNumber(loan),
    0,
    1
  )
  setResult(numberFormat(Math.round(result)))
}
const [depositError, setDepError] = useState(false)
const handleSubmit = event => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'formSubmit',
    'formType': 'mortgage-calculator',
    'formId': 'mortgage-calculator',
    'formName': 'Mortgage Calculator',
    'formLabel': 'Mortgage Calculator'
  });
  const form = event.currentTarget
  event.preventDefault()
  event.stopPropagation()
  if (form.checkValidity() === false) {
    setShowerror(true)
    setValidated(true)
  } else {
    setShowerror(false)
    var par = purchasePrice.replace('£', '').replace(/,/g, '')
    var dep = deposit.replace('£', '').replace(/,/g, '')
    var par1 = parseInt(par)
    var dep1 = parseInt(dep)
    if (
      parseInt(filterNumber(purchasePrice)) &&
      parseInt(filterNumber(deposit))
    ) {
      let loan =
        parseInt(filterNumber(purchasePrice)) -
        parseInt(filterNumber(deposit))
      setLoan(prefix + numberFormat(loan))
      if(interest === "0"){

      }
      else if(par1 < dep1){
        setDepError(true)
      }
      else {
        setDepError(false)
        getResult(interest, duration, loan)


      }
    }
  }
}
// const currency = props.currency

const [sresult, ssetResult] = useState(false)

const [propertyType, setPropertyType] = useState(props.propertyType)
const [propertyValue, setPropertyValue] = useState(
  currency + numberFormat(filterNumber(props.propertyValue))
)
const [buyer, setBuyer] = useState(props.buyerType)

const formatPrice = str => {
  return currency + str.toLocaleString("en-US")
}

const doCalculate = () => {
  const sresults = calculate(
    filterNumber(propertyValue),
    propertyType,
    countries.ENGLAND,
    buyer
  )
  // effective stamp duty rates
  // Formula => ((tax / price) * 100).
  // Ex: (213750/2500000) * 100 = 8.55%.
  let effectiveRate = (sresults.tax / filterNumber(propertyValue)) * 100
  effectiveRate = new Intl.NumberFormat("en-US", {}).format(effectiveRate)
  let summaryBands = []
  sresults.summaryBands.map(sresult => {
    summaryBands.push(
      ', '+sresult.adjustedRate +
        "% between " +
        formatPrice(sresult.start) +
        " and " +
        formatPrice(sresult.end)+ ''
    )
  })
  const sresult = {
    effectiveRate: effectiveRate + "%",
    summaryBands: summaryBands,
    tax: formatPrice(sresults.tax),
  }
  ssetResult(sresult)

}
const [depError, setdepError] = useState(false)
const shandleSubmit = event => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'formSubmit',
    'formType': 'stamp-duty-calculator',
    'formId': 'stamp-duty-calculator',
    'formName': 'Stamp Duty Calculator',
    'formLabel': 'Stamp Duty Calculator'
  });
  event.preventDefault()
  event.stopPropagation()
  if(propertyValue){
    setdepError(false)
    doCalculate()
  }
  else {
    setdepError(true)
    ssetResult(false)
  }

}

const shandleDeposit = event => {
  if(event.target.value !== '£' && event.target.value !== "£0" && event.target.value !== '0' && event.target.value !== ""){
  setPropertyValue(currency + numberFormat(filterNumber(event.target.value)))
  }
  else {
    setPropertyValue('')
  }
}

const handlePropertyType = event => {
  setPropertyType(event.target.value)
}

const handleBuyer = event => {
  setBuyer(event.target.value)
}

useEffect(() => {
  if (defaultResult) {
    if (
      parseInt(filterNumber(purchasePrice)) &&
      parseInt(filterNumber(deposit))
    ) {
      let loan =
        parseInt(filterNumber(purchasePrice)) -
        parseInt(filterNumber(deposit))
      setLoan(prefix + numberFormat(loan))
    }
    getResult(interest, duration, loan)
  }
}, [defaultResult, purchasePrice, deposit, loan, interest, duration, prefix])

  return(
     
<div className="calculate-section">
 <Container>
   {/* <Row className="d-lg-none">
     <Col xl="12">
       <div className="calculate-content">
       <p className="d-md-none">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus luctus dignissim. Vestibulum et accumsan lacus. Nullam suscipit ullamcorper turpis ac luctus nunc hendrerit nisl.</p>
       <p className="d-none d-md-block">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus luctus dignissim. Vestibulum et accumsan lacus. Nullam suscipit ullamcorper turpis ac luctus. Nunc hendrerit nisl mattis maximus mollis. Nullam consectetur pulvinar enim.</p>
       </div>
     </Col>
   </Row> */}
     <Row className="justify-align-center">
       {props.collection === 'Mortgage_Calculator' ?
         <Col xs="12" xl="6" className="d-lg-flex">
         <ScrollAnimation  className="calculate-block" animateIn='fadeIn' animateOnce={true}>
            <h2>Mortgage Calculator</h2>
            <p>Calculate your monthly mortgage payments.</p>
          <div className="calculate-form">
          {showerror && (
        <div className="alert-error">
          <p>Highlighted fields are required | invalid</p>
        </div>
      )}
                <Form name="MortgageCalc"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}>
          <input type="hidden" name="bot-field" />
                      <Form.Group controlId="purchase_price">
                        <Form.Label>Property Price</Form.Label>
                        <div className="form_input">
                          {/* <i>£</i> */}
                        <Form.Control 
                        className="form-control"
                        required
                        type="text"
                        name="purchase_price"
                        value={purchasePrice}
                        placeholder="Purchase Price"
                        onChange={handlePrice}
                         />
                        </div>
                         </Form.Group>
                      <Form.Group controlId="deposit">
                        <Form.Label>Deposit</Form.Label>
                        <div className="form_input">
                          {/* <i>£</i> */}
                        <Form.Control 
                        className={depositError ? "form-control error-field" : "form-control"}
                        required
                        type="text"
                        name="deposit_available"
                        value={deposit}
                        placeholder="Deposit Amount"
                        onChange={handleDeposit}
                        />
                        </div>
                        {depositError ? <div className="error">
                        Deposit Amount is greater than the Property Price amount 
                        </div> : null}
                      </Form.Group>
                      <Form.Group controlId="AnnualInterest">
                        <Form.Label>Annual Interest</Form.Label>
                        <div className="form_input">
                          <i className="input-text">%</i>
                        <Form.Control 
                        className={interest === "0" ? "form-control error-field" : "form-control"}
                        required
                        type="text"
                        name="interest_rate"
                        value={interest}
                        placeholder="Interest Rate"
                        onChange={handleInterest}
                        // type="text" value={interest} name='interest' onChange={onChange} placeholder="2.4" className="form-control--input"
                         />
                        </div>
                        {interest === "0" ? 
                         <div className="error">
                           Please enter above Zero %
                         </div>
                         : null}

                         </Form.Group>
                      <Form.Group controlId="duration">
                        <Form.Label>Repayment</Form.Label>
                        <div className="form_input field-yrs">
                          <i  className="input-text">Years</i>
                        <Form.Control 
                        as="select"
                        className="form-control"
                        required
                        name="duration"
                        value={duration}
                        placeholder="Duration (Years)"
                        onChange={handleDuration}
                        // type="text" value={repayment} name='repayment' onChange={onChange} placeholder="25"  className="form-control--input"
                         >
                           {durationOptions.map((value, key) => {
              return (
                <option value={value} key={key}>
                  {value} 
                </option>
              )
            })}
            </Form.Control>
                        </div>
                      </Form.Group>
                      <Button type="submit" className="btn btn-pink">
          Calculate
        </Button>
                </Form>
              </div>
              {result ? 
              <div className="total_block">
                <span className="total_text">Monthly Repayments</span>
                <span className="total_value">{currency} {result}</span>
              </div>
              : null }
            </ScrollAnimation>
          </Col> 
          :
          props.collection === 'Stamp_Duty_Calculator' ?
          <Col xs="12" xl="6" className="d-xl-flex">
            <ScrollAnimation className="calculate-block calculate-block--right" animateIn='fadeIn' animateOnce={true} delay={300}>
              <h2>Stamp Duty Calculator</h2>
              <p>Calculate how much stamp duty you will have to pay based on the purchase price of the property.</p>
              <div className="calculate-form">
                <Form 
                name="MortgageCalc"
                noValidate
                onSubmit={shandleSubmit}>
                  <input type="hidden" name="bot-field" />
        <Form.Row>
          <Form.Group controlId="property_type">
            {propertyTypes.map((item, i) => {
              return (
                <Form.Check
                  key={i}
                  type="checkbox"
                  name="property_type"
                  id={`default-radio-${i}`}
                  value={item.value}
                  label={item.label}
                  checked={propertyType === item.value}
                  onChange={handlePropertyType}
                  custom
                          inline 
                />
              )
            })}
          </Form.Group>
        </Form.Row>
                      <Form.Group controlId="deposit">
                        <Form.Label>Deposit Available</Form.Label>
                        <div className="form_input">
                          {/* <i>£</i> */}
                        <Form.Control 
                        // type="text" value={price} name='price' onChange={onChange} placeholder="400,000"
                        className="form-control"
            required
            type="text"
            name="deposit"
            value={propertyValue}
            placeholder="Purchase Price"
            onChange={shandleDeposit}
            maxLength={11}
                         />
                        </div>
                        {depError ?
                        <div className="error">This is required</div> : null}
                         </Form.Group>
                      {/* <Form.Group controlId="formCheckbox1">
                        <Form.Check 
                          custom
                          inline 
                          type="checkbox" 
                          label="I’m buying my first home or next home" />
                      </Form.Group>
                      <Form.Group controlId="formCheckbox2">
                        <Form.Check 
                          custom
                          inline 
                          type="checkbox" 
                          label="I’m buying an additional property or second home" />
                      </Form.Group> */}
                      <Form.Row>
          <Form.Group controlId="buyer">
            {buyerTypes.map((item, i) => {
              return (
                <Form.Check
                  key={i}
                  type="checkbox"
                  name="buyer"
                  id={`default-buyer-${i}`}
                  value={item.value}
                  label={item.label}
                  checked={buyer === item.value}
                  onChange={handleBuyer}
                  custom
                          inline 
                />
              )
            })}
          </Form.Group>
        </Form.Row>
        <Button type="submit" className="btn btn-pink">
          Calculate
        </Button>
                </Form>
              </div>
              {sresult &&
              <div className="total_block">
                <span className="total_text">Stamp Duty</span>
                <span className="total_value">{sresult?.tax}</span>
              </div>
              }
              {sresult && (
        <div className="alert-success stamp" style={{borderRadius: '0px',padding:'15px 10px', marginTop:'10px', backgroundColor:'#1a2440!important'}}>
          <div>
            <p>
              <span>You have to pay stamp duty: </span>
              {sresult?.summaryBands?.map((sm, i) => {
                return <span key={i}>{sm}</span> 
              })}
               <span> 
               &nbsp;Your effective stamp duty rate is {sresult.effectiveRate}
              </span>
              .
            </p>
          </div>
        </div>
      )}

            </ScrollAnimation>
          </Col>
          : null}
        </Row>
      </Container>
    </div>
  )
}

CalculatorBlock.defaultProps = {
  propertyType: `residential`,
  propertyValue: `10000000`,
  buyerType: `first`,
  propertyPrice: defaultValues.PROPERTY_VALUE,
  depositAmount: defaultValues.DEPOSIT,
  loadDuration: defaultValues.DURATION,
  interestRate: defaultValues.INTEREST,
  currency: process.env.CURRENCY
    ? process.env.CURRENCY
    : defaultValues.CURRENCY,
  defaultResult: defaultValues.DEFAULT_RESULT,
  pricePrefix: defaultValues.PRICE_PREFIX,
  // instantResult: defaultValues.INSTANT_RESULT,
  // durationSuffix: defaultValues.DURATION_SUFFIX,
  // yearSuffix: defaultValues.YEAR_SUFFIX,
  // yearSelect: defaultValues.YEAR_SELECT,
}

CalculatorBlock.propTypes = {
  propertyPrice: PropTypes.any,
  depositAmount: PropTypes.any,
  loadDuration: PropTypes.any,
  interestRate: PropTypes.any,
  currency: PropTypes.string,
  defaultResult: PropTypes.any,
  pricePrefix: PropTypes.any,
  propertyType: PropTypes.string,
  propertyValue: PropTypes.any,
  buyerType: PropTypes.string,
  // instantResult: PropTypes.any,
  // durationSuffix: PropTypes.any,
  // yearSuffix: PropTypes.any,
  // yearSelect: PropTypes.any
}

export default CalculatorBlock