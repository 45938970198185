import * as React from "react"
import { Link } from "@StarberryUtils"
import {Container, Row, Col} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import  './ListBlock.scss';
import ReactMarkdown from "react-markdown"
const ListBlock = ( props ) => {

  return(
    <div className="listblock-section">
      <Container>
        <Row>
          <Col lg={props.List_Type === 'Two' ? "4" : "8"}>
          <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
          <div className="listblock-text rich-text-editer-block">
          <ReactMarkdown source={props.Content} allowDangerousHtml />
          </div>
          </ScrollAnimation>
          </Col>
          <Col lg={props.List_Type === 'Two' ? "8" : "4"}>
          <div className="listblock-content">
          <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
           <h2>{props.Title}</h2>
           </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' animateOnce={true} delay={500}>
            <div className="d-md-flex list-wrap">
              <ul className={props.List_Type === 'Two' ? "double" : ""}>
                {props.Points.map((list, index) => {
                  return(
                  <li>
                  <Link> <i className="icon-bullet-arrow"></i> {list.List_item}</Link>
                  </li>
                  
                  )
                })}
              </ul>
            </div>
              </ScrollAnimation>
              </div>
          </Col>
        </Row>
      </Container>
    </div>
         
  
)
  }



export default ListBlock